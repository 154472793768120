@import 'src/common/styles/colors';

.BotMenuCell {
  &__modal {
    padding-left: 70px;
    padding-top: 40px;
    padding-bottom: 70px;

    .Modal__children {
      color: white;
      text-align: left;
    }
  }
}
