.NetworkButtonGroup {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;

  &__item {
    display: inline-block;
    text-align: center;
    padding: 8px 12px;
    font-size: 0.825rem;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid transparent;
    outline: none;
    background-color: rgba(255, 255, 255, 0.08);
    color: rgba(255, 255, 255, 0.64);
    min-width: fit-content;
    border-radius: 12px;
    white-space: nowrap;
    transition: all 0.2s ease-in;

    &:hover {
      background-color: rgba(255, 255, 255, 0.16);
    }

    &__selected,
    &__selected:hover {
      background-color: rgba(255, 255, 255, 0.32);
      color: rgba(255, 255, 255, 0.88);
    }
  }
}
