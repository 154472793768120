@import 'src/common/styles/colors';

.AlertFilter {
  margin-bottom: 10px;
  &__dropdown {
    max-width: 25%;
  }

  &__modal-content {
    min-width: 250px;
    margin-bottom: 40%;
    margin-top: 10%;
  }

  &__modal-content-consensus {
    min-width: 250px;
    margin-bottom: 20%;
    margin-top: 10%;
  }

  &__chips {
    margin-bottom: 24px;
    flex-wrap: wrap;
    max-width: 770px;

    .Chip {
      font-weight: 700;
      padding: 12px 14px;
      justify-content: space-around;
      min-width: 200px;
    }

    span {
      font-weight: 100;
    }

    .Chip__icon {
      width: auto;
      height: auto;
    }
  }

  &__modal-form {
    display: flex;
    flex-direction: column;
    margin-left: 7%;
  }

  &__form-source-value-scan-confirms-type {
    width: 100%;
    margin-top: 5%;

    .MuiToggleButtonGroup-root {
      background: $neutral-20;
    }
  }

  &__option-modal {
    display: flex;
    min-width: 320px;
    min-height: 33%;

    padding-right: 30px;
  }

  &__form-select {
    .Select {
      font-family: 'Courier New', monospace;
    }
  }

  &__form-submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      color: white;
      background-color: transparent;
      padding: 10px 12px;
      outline: none;
      font-size: 20px;
      border-radius: 10px;
      font-weight: 300;
      border: solid 1px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #3dbbd8 0%, #30fb8a 76.56%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #0f0f0f inset;

      &:hover {
        color: white;
      }
    }
  }

  &__filter-source-label {
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
