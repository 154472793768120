@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.AgentDeploymentPage {
  color: white;
  text-align: left;

  &__header {
    text-align: center;
    margin-bottom: 10px;
  }

  &__title {
    font-family: $font-display;
    font-weight: 500;
    font-size: 2rem;
  }

  &__description {
    font-family: $font-display;
    color: $neutral-45;
    margin-top: 4px;
  }

  &__denied {
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    color: $lightgray-2;
    border: 1px solid $gray;
    padding: 100px;
  }

  &__message {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__message-link {
    margin-left: 0.3em;
    color: $darkblue;
    transition: all 0.2s ease-in;

    &:hover {
      color: $blue;
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  align-items: baseline;
}

.button-cancel {
  font-size: 0.8em;
  color: $lightgray-2;
}

.button-submit {
  background-color: $purple;
  padding: 5px 15px;
  border-radius: 4px;
}
