@import 'src/common/styles/colors';

.MessageBox {
  background-color: rgba(39, 39, 39, 1);
  color: rgb(189, 189, 189);
  padding: 1rem;
  margin: 20px;
  border-left: 3px solid $darkblue;

  &--warning {
    border-left: 3px solid $yellow;
  }

  &__header {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: white;
    margin-bottom: 5px;

    &-icon {
      color: $blue;

      &--warning {
        color: $yellow;
        width: 32px;

        svg {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}
