@use 'sass:color';
@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.BotDocumentation {
  $module: &;

  display: flex;
  margin-top: 40px;
  text-align: left;
  width: 100%;

  &__markdown-container {
    width: 80%;
    padding: 30px;
    background-color: $gray-4;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    #{$module}__markdown {
      background-color: transparent;

      pre {
        background-color: $neutral-15;
      }
    }
  }

  &__skeleton {
    line-height: 170%;
  }

  &__scroll {
    @include transition-slow();

    flex: 1;
    display: flex;
    justify-content: center;
    background-color: color.scale($grey-1, $lightness: -10%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &-label {
      display: flex;
      gap: 8px;
      position: sticky;
      top: 40px;
      margin-top: 40px;
      color: $neutral-50;
      font-weight: 500;
    }

    &:hover {
      background-color: color.scale($grey-1, $lightness: 3%);
    }
  }

  @media #{$breakpoint-md-down} {
    &__markdown-container {
      width: 100%;
    }

    &__scroll {
      display: none;
    }
  }
}
