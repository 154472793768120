@import 'src/common/styles/colors';

.BotAlerts {
  margin-top: 50px;
  border-radius: 20px;
  overflow: hidden;

  &__see-all {
    margin-top: 30px;
  }

  .Alerts__list-header {
    background-color: $gray-4;
  }
}
