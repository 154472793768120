@import 'src/common/styles/colors';

.FileSelector {
  background-color: $gray-2;
  cursor: pointer;
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.2s ease-out;

  input {
    display: none;
  }

  &__label {
    display: inline-block;
  }

  &__upload-text {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    color: $lightgray-2;
  }

  &__upload-icon {
    margin-right: 6px;
  }

  &__file-name {
    font-style: italic;
    margin-top: 10px;
    text-align: right;
    color: white;
  }

  &__subtext {
    color: red;
    font-size: 0.8em;
  }

  &.errored {
    border: 1px solid red;
  }
}
