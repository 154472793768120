@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.DataPlanList {
  $module: &;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  &__data-plan {
    width: 406.67px;

    &:last-child {
      .DataPlanCard {
        border: 2px solid rgba(18, 92, 92, 1);
      }
    }

    &.transparent {
      .DataPlanCard {
        background: rgba(13, 38, 38, 0.2);
        border: 1px solid rgba(18, 92, 92, 0.4);
      }
    }
  }
}

/* Tablet */
// @media #{$breakpoint-md-down} {
//   .DataPlanList {
//     &__list {
//       grid-template-columns: 1fr 1fr;
//     }
//   }
// }

// /* Mobile */
// @media #{$breakpoint-sm-down} {
//   .DataPlanList {
//     &__list {
//       grid-template-columns: 1fr;
//     }
//   }
// }
