@import 'src/common/styles/colors';

.AgentDeploymentPreview {
  margin: 0 auto;
  max-width: 900px;
  background-color: $darkgray;
  position: relative;
  margin-top: 30px;
  padding: 20px;

  &__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &-label {
      width: 200px;
    }

    &-value {
      color: $lightgray-2;
      // overflow: auto;
      max-width: 650px;
      word-wrap: break-word;
      overflow-y: auto;

      a:hover {
        color: white;
      }
    }
  }

  &__network {
    margin-right: 10px;
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(black, 0.7);
    backdrop-filter: blur(2px);
    color: $lightgray-2;

    .Loader {
      margin-bottom: 10px;
    }
  }

  &__button-deploy {
    background-color: $purple;
    padding: 5px 15px;
    border-radius: 4px;
  }
}
