@import 'src/common/styles/colors';
// @import "compass";

// VARS
$circle-width: 0.09em;
$circle-width-hover: 0.07em;

// colors default
$primary-color: #000000; // czarny
$secondary-color: $lightgray-2; //szary bcg
$bg-color: $lightgray; //srodkowy bezowy

// colors customized
$primary-color-blue: #30bae7;
$primary-color-green: #15c7a8;
$primary-color-orange: #eb7d4b;
$primary-color-pink: #d74680;
$primary-color-span: #3c4761;

// CIRCLE
// classes 2 extend
.rect-auto {
  clip: rect(auto, auto, auto, auto);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

.pie {
  position: absolute;
  border: $circle-width solid $primary-color;
  width: 1 - (2 * $circle-width);
  height: 1 - (2 * $circle-width);
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  @include rotate(0deg);
}

.pie-fill {
  @include rotate(180deg);
}

// main
.c100 {
  *,
  *:before,
  *:after {
    @include box-sizing(content-box);
  }

  position: relative;
  font-size: 160px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  float: left;
  margin: 0.4em;
  background-color: $secondary-color;

  // 	// center circle to its parent
  // 	&.center{
  // 		float: none;
  // 		margin: 0 auto;
  // 	}

  // 	&.small{
  // 		font-size: 80px;
  // 	}

  // centered value inside circle
  > span {
    position: absolute;
    width: 100%;
    z-index: 1;
    left: 0;
    top: 0;
    width: 5em;
    line-height: 5em;
    font-size: 0.2em;
    color: $primary-color-span;
    display: block;
    text-align: center;
    white-space: nowrap;
    transition: all 0.2s ease-out;
  }

  // background inside the circle
  &:after {
    position: absolute;
    top: $circle-width;
    left: $circle-width;
    display: block;
    content: ' ';
    border-radius: 50%;
    background-color: $bg-color;
    width: 1 - (2 * $circle-width);
    height: 1 - (2 * $circle-width);
    transition: all 0.2s ease-out;
  }

  // the slice (mask)
  .slice {
    position: absolute;
    width: 1em;
    height: 1em;
    clip: rect(0em, 1em, 1em, 0.5em);
  }

  // circle to show the status
  .bar {
    @extend .pie;
  }

  // loop to create all needed elements automatically
  @for $j from 51 through 100 {
    &.p#{$j} .slice {
      @extend .rect-auto;
    }

    &.p#{$j} .bar:after {
      @extend .pie-fill;
    }

    &.p#{$j} .fill {
      @extend .pie;
      @extend .pie-fill;
    }
  }

  // loop to rotate all 100 circles
  @for $j from 1 through 100 {
    &.p#{$j} .bar {
      @include rotate((360/100 * $j) + deg);
    }
  }

  &:hover {
    cursor: default;

    > span {
      width: 3.33em;
      line-height: 3.33em;
      font-size: 0.3em;
      color: $primary-color-span;
    }

    &:after {
      top: $circle-width-hover;
      left: $circle-width-hover;
      width: 1 - (2 * $circle-width-hover);
      height: 1 - (2 * $circle-width-hover);
    }
  }

  &.blue {
    .bar,
    .fill {
      border-color: $primary-color-blue !important;
    }
    &:hover {
      > span {
        color: $primary-color-span;
      }
    }
  }

  // pink skin
  &.pink {
    .bar,
    .fill {
      border-color: $primary-color-pink !important;
    }

    &:hover {
      > span {
        color: $primary-color-span;
      }
    }
  }

  // green skin
  &.green {
    .bar,
    .fill {
      border-color: $primary-color-green !important;
    }

    &:hover {
      > span {
        color: $primary-color-span;
      }
    }
  }

  // orange skin
  &.orange {
    .bar,
    .fill {
      border-color: $primary-color-orange !important;
    }

    &:hover {
      > span {
        color: $primary-color-span;
      }
    }
  }
}

.StakingForm {
  &__content {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    column-gap: 20px;
    margin-top: 30px;
  }

  &__form-container {
    flex: 1;
  }

  &__information {
    flex: 1;
  }

  &__form {
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  &__amount-visual {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-bottom: 20px;

    &-title {
      font-size: 1.2em;
    }

    &-graph {
      margin: 0;
      font-size: 150px;
    }
  }

  &__amount {
    margin: 0 auto;
    position: relative;
    width: 300px;

    .Input {
      font-size: 2em;
      padding-right: 3.5em;
    }

    &-label {
      font-size: 2em;
      position: absolute;
      top: 8px;
      right: 10px;
      color: #a1a1aa;
    }
  }

  &__submit {
    text-align: center;

    .button-cta-2:disabled {
      opacity: 0.6;
      cursor: default;

      &.button-cta-2:hover {
        opacity: 0.6;
      }
    }

    .Loader {
      width: 20px;
    }
  }

  &__approved {
    display: flex;
    justify-content: center;
    column-gap: 6px;
    margin-top: 5px;
    margin-bottom: 20px;

    &-amount {
      font-style: italic;
      color: $lightgray-2;
    }
  }

  &__insufficientApproval {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 6px;
    margin: 10px auto 15px auto;
    align-items: center;
    width: 75%;

    &-label {
      span {
        color: $yellow;
      }
    }

    &-amount {
      font-style: italic;
      color: $lightgray-2;
    }
  }
}
