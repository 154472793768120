@import 'src/common/styles/colors';

.Pager {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  &__label {
    color: $gray;

    &-number {
      color: white;
    }
  }
}

@media screen and (max-width: 460px) {
  .Pager__label {
    display: none;
  }

  .Pager__nav {
    position: relative;
    height: 30px;
    width: 100%;
  }

  .Pager__nav-prev {
    position: absolute;
    left: 0;
  }

  .Pager__nav-next {
    position: absolute;
    right: 0;
  }
}
