@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TransactionItem {
  margin-bottom: 25px;

  &__label {
    margin-bottom: 10px;
  }

  &__link {
    display: inline-flex;
    vertical-align: sub;
  }
}

.PendingTransactionList {
  background-color: $gray-2;
  border-radius: 4px;
  border: 1px solid $neutral-30;
  text-align: left;
  padding: 20px 15px;
  font-family: $font-text;
  color: $lightgray-3;
  z-index: 1;

  &__syncing {
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 0.8rem;
    color: $lightgray-2;
  }

  .PendingTransactionItem {
    font-size: 0.75rem;
    margin-bottom: 20px;
    position: relative;

    &__label {
      text-align: left;
      margin-bottom: 0.4rem;

      &__amount {
        margin-left: 0.3rem;
      }
    }

    &__link {
      transform: scale(0.7);
      // font-size: .7rem;
      text-decoration: underline;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: $lightgray-2;
      }
    }
  }

  &__description {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }
}
