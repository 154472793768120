@import 'src/common/styles/colors';

$sizes: (
  xs: 16px,
  sm: 20px,
  md: 48px,
  lg: 96px
);

.Spinner {
  $module: &;

  display: inline-block;

  &--dual-ring {
    position: relative;

    &::after {
      content: ' ';
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 50%;
      box-sizing: border-box;
      border: 6px solid transparent;
      border-color: $lightgray transparent $lightgray transparent;
      animation: lds-dual-ring 0.75s linear infinite;
    }

    @each $name, $size in $sizes {
      &#{$module}--#{$name} {
        width: $size;
        height: $size;

        &::after {
          border-width: 0.15 * $size;
        }
      }
    }
  }

  &--inherit {
    &::after {
      border-top-color: inherit;
      border-bottom-color: inherit;
    }
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
