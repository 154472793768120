@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.PlanPurchaseModal {
  width: 100%;
  max-width: 1300px;
  padding: 0;
  padding-bottom: 70px;

  &__header-title {
    font-size: 1.4em;
    font-weight: bold;
    margin-bottom: 0.1em;
    padding: 15px;
    background-color: $gray-2;
  }

  &__header-banner {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    margin-top: 50px;

    img {
      width: 200px;
    }
  }

  &__header-attributes {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
  }

  &__header-attribute {
    display: inline-flex;
    gap: 10px;
  }

  &__payment-currency {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__payment-in-fort {
    color: $gray;

    &-unit {
      margin-left: 0.3em;
      color: white;
    }
  }

  &__payment-currency-selector {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 34px;
    }

    .slider:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }

    input:checked + .slider {
      background-color: $purple;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $purple;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }

  &__data-plans-title {
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
  }

  &__plans {
    margin-top: 20px;
    padding: 0 40px;
  }
}
