@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.Button {
  $module: &;

  @include transition-medium();

  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  padding: 0;
  text-decoration: none;
  appearance: none; // helps to fix Safari issues
  flex-shrink: 0;
  border-radius: 6px;
  line-height: 100%; // fix font-alignment
  font-family: $font-text;

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }

  &--default {
    background-color: $gray-2;
    color: $lightgray-2;

    &:not(:disabled) {
      &:hover {
        background-color: lighten($gray-2, 5%);
      }

      &:active {
        background-color: lighten($gray-2, 9%);
      }
    }
  }

  &--primary {
    color: $lightgray;
    background-color: $purple;

    &:not(:disabled) {
      &:hover {
        background-color: lighten($purple, 4%);
      }

      &:active {
        background-color: lighten($purple, 9%);
      }
    }
  }

  &--secondary {
    background-color: $black;
    color: $lightgray-2;
    border: 1px solid transparent;

    &:not(:disabled) {
      &:hover {
        border: 1px solid $blue;
      }

      &:active {
        background-color: lighten($black, 6%);
      }
    }
  }

  &--light-outline {
    @include transition-slow();

    border: 1px solid $neutral-35;
    border-radius: 24px;

    &:not(:disabled) {
      &:hover {
        color: #33ffff;
        border: solid 1px #33ffff;
        background-image: linear-gradient(
          135deg,
          rgba(26, 255, 217, 0.1) 0,
          rgba(76, 210, 255, 0.1) 100%
        );

        #{$module}__spinner {
          border-color: #33ffff;
        }
      }

      &:active {
        color: #33ffff;
        background-image: linear-gradient(
          135deg,
          rgba(26, 255, 217, 0.25) 0,
          rgba(76, 210, 255, 0.25) 100%
        );
      }
    }

    &:disabled {
      font-style: italic;
    }
  }

  &--outline {
    color: $neutral-100;
    border: 1px solid $neutral-100;

    &:not(:disabled) {
      &:hover {
        color: $black;
        background-color: $neutral-100;

        #{$module}__spinner {
          border-color: $black;
        }
      }

      &:active {
        color: $black;
        background-color: darken($neutral-100, 15%);
      }
    }
  }

  &--tertiary {
    color: $gray;
    background-color: transparent;

    &:not(:disabled) {
      &:hover {
        color: $lightgray;
      }

      &:active {
        color: darken($lightgray, 20%);
      }
    }
  }

  &--lg {
    font-size: 16px;
    font-weight: 500;
    height: 50px;
    padding-left: 18px;
    padding-right: 18px;
    gap: 8px;

    svg {
      width: 20px;
      height: 20px;
    }

    &#{$module}--icon {
      width: 50px;
      padding-right: 0;
      padding-left: 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &#{$module}--round {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &--md {
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    padding-left: 16px;
    padding-right: 16px;
    gap: 8px;

    svg {
      width: 20px;
      height: 20px;
    }

    &#{$module}--icon {
      width: 44px;
      padding-right: 0;
      padding-left: 0;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    &#{$module}--round {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &--sm {
    font-size: 14px;
    font-weight: 400;
    height: 36px;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }

    &#{$module}--icon {
      width: 36px;
      padding-right: 0;
      padding-left: 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &#{$module}--round {
      padding-left: 14px;
      padding-right: 14px;
    }
  }

  &--round {
    border-radius: 34px;
  }

  &--fluid {
    width: 100%;
  }
}
