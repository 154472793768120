@use './Table.module';

@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.TableSubHeading {
  $module: &;

  @include transition-medium();

  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 10px Table.$padding;
  margin: 0;
  background-color: transparent;
  text-align: left;
  font-weight: 500;
  line-height: 24px;

  &:after {
    @include transition-fast();

    content: '';
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    height: 1px;
    background-color: transparent;
  }

  &__tooltip {
    margin-right: 4px;
  }

  &__sort-icon {
    @include transition-medium();

    opacity: 0;
    color: $neutral-70;
    transform: rotate(90deg) rotateY(0);
    margin-top: -2px;

    > svg {
      width: 20px;
      height: 20px;
    }

    &--desc {
      transform: rotate(-90deg);
    }
  }

  &--sortable {
    &:hover {
      #{$module}__sort-icon {
        opacity: 0.5;
      }

      &:after {
        background-color: $neutral-45;
      }
    }

    &:active {
      #{$module}__sort-icon {
        opacity: 0.7;
      }

      &:after {
        background-color: $neutral-60;
      }
    }
  }

  &--ordered {
    #{$module}__sort-icon {
      opacity: 1 !important;
    }
  }
}
