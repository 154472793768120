@import 'src/common/styles/colors';

.TableBody {
  position: relative;

  &--loading,
  &--empty {
    min-height: 170px;
  }

  &__notification {
    padding: 0 20px;
    padding-top: 60px;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;

    &-message {
      color: $neutral-50;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: transparentize($neutral-20, 0.4);

    &__shimmer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.05) 30%,
        rgba(#fff, 0.1) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 1.7s infinite ease;
      opacity: 0.6;

      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    }
  }

  &--loading {
    // TableBody embeds Loader at the end of the list, so the last TableRow comes before the last
    .TableRow:nth-last-child(2) {
      border-bottom: none;
    }
  }
}
