@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/transitions';
@import 'src/common/styles/mixins/container';

$height-desktop: 100px;
$height-mobile: 60px;

.BaseHeader {
  $module: &;

  @include transition-slow();
  margin-bottom: 30px;

  &__container {
    @include container;

    display: flex;
    align-items: center;
    width: 100%;
    height: $height-desktop;
  }

  &__logo {
    img {
      height: 20px;
      width: auto;
    }
  }

  // prioritize this style with high specificity
  #{$module}__menu-button {
    display: none;
    color: $lightgray;
    margin-left: auto;
  }

  &__menu {
    flex: 1;

    &-container {
      display: flex;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: 30px;
  }

  &__nav-items {
    display: flex;
    gap: 24px;
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      position: relative;
      padding: 12px 0;
      margin: 0;
    }
  }

  &__separator {
    width: 2px;
    height: 20px;
    background-color: $lightgray;
  }

  &__additional-menu {
    display: flex;
    align-items: center;
    margin-left: 24px;
  }

  &__overlay {
    display: none;
  }

  // tablet
  @media only screen and (min-width: $md-start) and (max-width: $lg-max) {
    &__container {
      height: ($height-desktop + $height-mobile) / 2;
    }
  }

  // mobile
  @media #{$breakpoint-md-down} {
    &__overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $z-index-header - 1;
      background-color: transparent;
      pointer-events: none;
      transition: all 0.45s ease;
    }

    &__container {
      height: $height-mobile;
    }

    &__logo,
    &__menu-button {
      position: relative;
      z-index: $z-index-header;
    }

    // prioritize this style with high specificity
    #{$module}__menu-button {
      display: flex;
      margin-right: -10px;
    }

    &__menu {
      $element: &;

      position: fixed;
      display: flex;
      flex-direction: column;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: $black;
      max-width: 500px;
      // transform makes the animation smoother
      transform: translateX(100%);
      z-index: $z-index-header - 1;

      &-container {
        display: flex;
        flex-direction: column;
        margin-top: $height-mobile;
        padding-bottom: $height-mobile;
        // it enables scrolling
        overflow-y: auto;
      }

      &--animated {
        transition: all 0.45s ease;
      }
    }

    &__nav-items {
      flex-direction: column;
      width: 100%;
      gap: 0;

      > li {
        position: relative;
        padding: 0;

        &:not(:last-child) {
          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 1px;
            margin: 0 10px;
            margin-top: -1px;
            background-color: $neutral-30;
          }
        }
      }
    }

    &__separator {
      position: relative;
      width: 100%;
      height: 0;

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        margin: 0 10px;
        margin-top: -1px;
        background-color: $neutral-30;
      }
    }

    &__navigation {
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      gap: 0;
    }

    &__additional-menu {
      margin-top: 20px;
      margin-left: 0;
      padding: 0 20px;
    }

    &--open {
      #{$module}__menu {
        transform: translateX(0);
      }

      #{$module}__overlay {
        pointer-events: auto;
        background-color: transparentize($darkgray, 0.1);
      }
    }
  }

  @media screen and (max-width: $sm-max) {
    &__menu {
      max-width: 100%;
    }
  }
}

.MenuItem {
  $module: &;

  @include transition-slow();

  position: relative;
  display: flex;
  align-items: center;
  color: $neutral-40;
  padding: 3px 0;
  cursor: pointer;
  box-shadow: 0 2px 0 transparent;

  &__label {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    gap: 4px;

    #{$module}__badge {
      padding: 2px 4px;
      border-radius: 30px;
      font-size: 11px;
      font-weight: 500;
      line-height: 100%;

      &--new {
        color: $lightgray;
        background-color: $accent-pink-10;
      }
    }
  }

  &__icon {
    margin-left: 4px;

    svg {
      width: 16px;
      height: 16px;
    }

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &:hover {
    color: $lightgray;
  }

  &--active {
    color: $lightgray;
    box-shadow: 0 2px 0 white;

    &#{$module}--expandable {
      box-shadow: none;
    }
  }

  @media only screen and (min-width: $lg-start) and (max-width: $lg-max) {
    &__label {
      font-size: 15px;
    }
  }

  @media #{$breakpoint-md-down} {
    padding: 24px 20px;

    &__collapse {
      @include transition-slow();

      opacity: 1;

      &[aria-hidden='true'] {
        opacity: 0.25;
      }
    }

    &__label {
      flex-direction: row-reverse;
      gap: 10px;
    }

    #{$module}__icon {
      margin-left: auto;
      svg {
        width: 20px;
        height: 20px;
      }
    }

    &--active {
      box-shadow: none;
    }
  }
}

.MenuNestedItems {
  @include transition-fast();

  position: absolute;
  top: calc(100%);
  left: -20px;
  list-style: none;
  padding: 6px 0;
  margin: 0;
  width: 400px;
  border-radius: 12px;
  border: 1px solid $neutral-30;
  background-color: $neutral-15;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.22);
  z-index: 1;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-16px) scale(0.97);

  // arrow shape
  &:before {
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: -8px;
    left: 70px;
    transform: rotate(45deg);
    background-color: $neutral-15;
    border-left: 1px solid $neutral-30;
    border-top: 1px solid $neutral-30;
  }

  > li {
    // make the items over the arrow shape
    position: relative;
    z-index: 2;
    padding: 0;

    // add bottom line
    &:not(:last-child) {
      margin-bottom: -1px;

      &:after {
        display: block;
        content: '';
        height: 1px;
        background-color: $neutral-30;
        margin: 0 16px;
        margin-top: -1px;
      }
    }
  }

  &--expanded {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  @media #{$breakpoint-md-down} {
    position: static;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.03);
    opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: none;

    // disable arrow
    &:before {
      display: none;
    }
  }
}

.MenuNestedItem {
  $module: &;

  @include transition-fast();

  display: flex;
  padding: 16px;
  text-align: left;
  z-index: 2;

  &__icon {
    color: $lightgray;
    margin-right: 12px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  &__body {
    #{$module}__title {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: $lightgray-2;
    }

    #{$module}__badge {
      font-size: 11px;
      line-height: 100%;
      padding: 2px 6px;
      border-radius: 29px;
      margin-left: 10px;

      &--new {
        color: $lightgray;
        background-color: $accent-pink-10;
      }

      &--upcoming {
        color: $black;
        background-color: $neutral-40;
      }
    }
  }

  &__description {
    display: flex;
    margin-top: 6px;
    font-size: 16px;
    line-height: 23px;
    color: $neutral-50;
  }

  &:hover:not(&--disabled) {
    background-color: $neutral-30;

    #{$module}__title {
      color: white;
    }

    #{$module}__description {
      color: $lightgray-2;
    }
  }

  &--disabled {
    #{$module}__title,
    #{$module}__description,
    #{$module}__icon {
      color: $neutral-40;
    }
  }
}
