@import 'src/common/styles/colors';

.Input {
  border: 1px solid transparent;
  transition: all 0.2s ease-out;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: rgba(212, 212, 216, 1);
  padding: 0.5rem 1rem;
  background-color: $darkgray;
  align-items: center;
  border-radius: 5px;
  display: flex;
  position: relative;

  &:focus-within {
    border-color: $blue;
  }

  &__input {
    padding: 0.125rem;
    background-color: transparent;
    width: 100%;

    &:focus {
      outline: 0;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &__button {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-left: 1rem;
    color: rgb(161, 161, 170);
    transition: all 150ms ease-in;
    outline: none;

    &:hover {
      color: rgb(244, 244, 245);
    }
  }

  &__subtext {
    position: absolute;
    color: $black;
    left: 5px;
    bottom: -20px;
    font-size: 0.8em;
  }

  &--error,
  &--error:focus-within {
    border-color: red;

    .Input__input,
    .Input__subtext {
      color: red;
    }
  }
}
