@use 'sass:color';

@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

@import './TableRow';

.TableExpandableRow {
  $module: &;

  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  &__row {
    @extend .TableRow;
    @extend .TableRow--default;
  }

  &__collapse {
    @include transition-slow();
  }

  &__content {
    padding: 20px 0px 10px;
    background-color: darken($gray-3, 4%);
  }
}

.TableExpandSubHeading {
  flex: unset;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TableExpandSubCell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;

  &__icon {
    @include transition-slow();

    svg {
      width: 24px;
      height: 24px;
    }

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
