@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.Link {
  display: inline;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 1em;
  color: $accent-blue-30;

  &:hover {
    text-decoration: underline;
  }
}
