@import 'src/common/styles/colors';

.AgentForm {
  max-width: 820px;
  margin: 0 auto;
  // background-color: rgba(white, 0.15);
  padding: 20px;
  border-radius: 10px;

  &__field {
    margin: 20px 0;
    font-size: 0.9em;

    .Input {
      background-color: $gray-2;
    }
  }

  &__field-group {
    display: flex;
    margin: 20px 0;

    .AgentForm__field {
      margin: 0;
    }
  }

  &__field-version {
    width: 15%;
    min-width: 90px;
  }

  &__field-repository {
    width: 85%;
    padding-left: 20px;
  }

  &__label {
    margin-bottom: 10px;
  }

  &__id {
    font-size: 0.9em;
    background-color: $gray-2;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  &__field {
    .file-input {
      color: $lightgray-2;
      border: 1px solid $gray;
      padding: 20px 10px;
      max-width: 400px;
      border-radius: 10px;
      transition: all 0.2s ease-out;
      cursor: pointer;

      &.errored {
        border-color: red;
      }

      &-description {
        text-align: right;
        font-style: italic;
        color: $gray;
      }

      svg {
        display: inline-block;
        width: 3em;
        height: 3em;
      }
    }

    .asterisk {
      color: red;
    }
  }

  &__checkbox {
    display: inline-flex;
    align-items: center;

    &-tooltip {
      margin-left: 5px;
    }
  }
}
