@use 'sass:color';
@use './Table.module';

@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.Table {
  --table-bg: #{$gray-2};
  --table-head-bg: #{$darkgray};
  --table-border-radius: #{Table.$border-radius};

  font-family: $font-display;
  overflow-x: auto;
  word-break: break-word;
  background-color: var(--table-bg);
  border-radius: var(--table-border-radius);

  &--transparent {
    --table-bg: transparent;
    --table-head-bg: transparent;
    --table-border-radius: 0;
  }
}
