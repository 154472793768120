@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.ProfileDelegations {
  $module: &;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: left;

    #{$module} {
      &__title {
        font-size: 2rem;
      }

      &__description {
        font-size: 16px;
        color: $neutral-70;
      }

      &__delegated-tokens {
        margin: 0 0.25em;
      }
    }
  }

  &__section {
    margin-bottom: 40px;
  }
}
