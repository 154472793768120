.copy-container {
  display: inline-block;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.copy-icon {
  outline: none;
  display: inline-block;
  margin-left: 10px;
}
