@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.DataPlanCard {
  $module: &;

  padding: 24px;
  border-radius: 24px;
  border: 1px solid rgba(2, 3, 3, 0.25);
  background: linear-gradient(
    141deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  backdrop-filter: blur(40px);
  position: relative;
  text-align: left;
  height: 100%;
  transition: all 0.2s ease-out;

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__subscribers {
    display: flex;
    padding: 7px 12px 8px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    opacity: 0.8999999761581421;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 16px;
    right: 16px;
    color: #7e8c8c;
    font-size: 13px;
    // font-family: Neue Haas Grotesk Text Pro;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
  }

  &__icon {
    margin-bottom: 32px;
  }

  &__title {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    font-family: $font-display;
  }

  &__author {
    color: #646568;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-top: 8px;
    transition: all 0.2s ease-out;

    &-value {
      color: #7e888c;
      font-weight: 500;
      margin-left: 2px;
      transition: all 0.2s ease-out;
    }
  }

  &__description {
    margin-top: 32px;
    padding-right: 16px;
    color: #8f9ca3;
    font-family: $font-text;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    min-height: 163px;
    transition: all 0.2s ease-out;
  }

  &__learn-more {
    color: white;
    text-decoration: underline;
  }

  &__extra-description {
    padding-top: 20px;
  }

  &__share-bot-ideas {
    text-decoration: underline;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15px;
    margin-top: auto;
  }

  &__price {
    font-size: 1em;
    color: white;
    font-weight: bold;

    &-period {
      color: $neutral-35;
      font-weight: 400;
    }
  }

  &__attributes {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 32px;
  }

  &__attribute {
    display: flex;
    gap: 8px;
    align-items: center;
    color: #afb1b6;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;

    svg {
      width: 16px;
    }

    .checked {
      color: $cyan-2;
    }

    .unchecked {
      color: $orange-2;
    }
  }

  &__cta {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: auto;
  }

  &:hover {
    border: 1px solid #1cfdd7;
    // border-image-source: radial-gradient(127.23% 100% at 2.83% -0.07%, #1CFDD7 0%, rgba(0, 170, 255, 0.8) 100%);
    box-shadow: 0px 8px 24px 0px rgba(149, 253, 246, 0.2);

    #{$module}__author {
      color: #b3b3b7;

      &-value {
        color: #cacbce;
      }
    }

    #{$module}__description {
      color: #afb1b6;
    }
  }
}
