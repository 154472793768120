@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TransactionsWidget {
  position: relative;
  display: inline-block;
  margin-right: 10px;

  &__trigger {
    display: flex;
    justify-content: center;
    background-color: $gray-2;
    padding: 4px 10px;
    gap: 8px;
    border-radius: 6px;
    font-family: $font-text;

    .Loader {
      width: 16px;
    }
  }

  &__list-container {
    margin-top: 10px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 2;
  }

  &--expanded {
    .TransactionsWidget__trigger {
      background-color: $purple;
    }

    .TransactionsWidget__list-container {
      opacity: 1;
      pointer-events: auto;
      user-select: auto;
    }
  }
}
