@use './Table.module';

@import 'src/common/styles/colors';

.TableSubCell {
  display: flex;
  align-items: center;
  text-align: left;
  padding-left: Table.$padding;
  padding-right: Table.$padding;
  height: 100%;
  font-size: 16px;

  &--left {
    justify-content: flex-start;
  }

  &--right {
    justify-content: flex-end;
  }

  &--noPadding {
    padding-left: 0;
    padding-right: 0;
  }

  .button {
    display: flex;
    padding-left: 5px;
  }

  &__healthIndicator {
    padding: 2px 10px;
    border-radius: 6px;

    &--excellent {
      background: rgba(73, 188, 131, 0.07);
      color: $green;
    }

    &--good {
      color: $yellow;
      background: rgba(255, 211, 49, 0.07);
    }

    &--unsatisfactory {
      color: $orange;
      background: rgba(255, 84, 17, 0.07);
    }

    &--poor {
      color: $red;
      background: rgba(255, 0, 60, 0.07);
    }
  }
}
