@import 'src/common/styles/colors';

.Filters {
  background-color: rgba(white, 0.2);
  border-radius: 6px;
  padding: 30px 20px;
  margin-bottom: 16px;
  --tw-bg-opacity: 0.5;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 16px;
  }

  &__text-filters {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    row-gap: 20px;
    column-gap: 40px;
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 9;
    row-gap: 20px;
  }

  &__text,
  &__transaction {
    width: 100%;
  }

  &__option-panel {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__saved {
    margin-left: auto;
  }

  &__network {
    width: 400px;
  }

  &__title {
    color: white;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: left;
  }

  &__addresses {
    width: 100%;
  }

  &__agents {
    width: 100%;
  }

  &__multifilters {
    margin-top: 15px;
    display: flex;
    gap: 30px;
  }
}

@media screen and (max-width: 610px) {
  .Filters__text-filters,
  .Filters__multifilters {
    flex-wrap: wrap;
  }
}
