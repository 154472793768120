@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.Chip {
  $module: &;

  @include transition-medium();

  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 10px;

  &__icon {
    width: 20px;
    height: 20px;
  }

  &--default {
    color: $neutral-50;
    background-color: $neutral-15;

    &:hover {
      background-color: lighten($neutral-15, 5%);
    }

    &:active,
    &#{$module}--active {
      color: $neutral-70;
      background-color: $neutral-30;
    }
  }

  &--outline {
    color: $neutral-50;
    border: 1px solid $neutral-30;

    &:hover {
      border-color: $neutral-100;
    }

    &:active,
    &#{$module}--active {
      color: $black;
      border-color: $neutral-100;
      background-color: $neutral-100;
    }
  }
}

.ChipContainer {
  display: flex;
  gap: 10px;

  > * {
    flex-shrink: 0;
  }

  &--row {
    flex-direction: row;
    overflow-x: auto;
  }

  &--column {
    flex-direction: column;
  }
}
