@import 'src/common/styles/colors';

.NotificationModal {
  top: 40px;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, 0);
  color: white;
  overflow: auto;
  outline: none;
  padding: 20px;
  padding-right: 70px;
  padding-top: 30px;

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(black, 0.5);
    backdrop-filter: blur(2px);
    animation-name: overlay-animation;
    animation-duration: 0.3s;
    overflow-y: auto;
  }

  &__subscribe_to_alert_section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__close-button {
    position: absolute;
    right: 36px;
    top: 26px;
  }

  .Modal__close-button {
    display: none;
  }

  &__form {
    margin-top: 30px;
    margin-left: 40px;
  }

  &__form-source {
    display: flex;
    position: relative;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 30px;
  }

  &__form-source-scan-confirms {
    display: flex;
    justify-content: space-between;
    position: relative;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 30px;
  }

  &__form-source-label {
    text-align: center;
    font-size: 1.75em;
    margin-bottom: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #3eb3e0 0%, #30fb89 39.98%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;

    .InfoPopover__flyout {
      font-size: 0.8em;
    }
  }

  &__form-source-required-label {
    text-align: center;
    font-size: 1.125em;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    .InfoPopover__flyout {
      font-size: 0.8em;
    }
  }

  &__filter-source-label {
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .InfoPopover__flyout {
      font-size: 0.8em;
    }

    .InfoPopover {
      margin-left: 10px;
    }
  }

  &__filter-source-label-scanner {
    text-align: center;
    font-size: 0.8em;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__form-source-type {
    flex: 1;
  }

  &__form-source-value {
    width: 100%;

    .Input {
      font-family: 'Courier New', monospace;
    }

    &:focus-within {
      .NotificationModal__fill-wallet-btn {
        display: block;
      }
    }
  }

  &__form-source-value-scan-confirms {
    width: 100%;
    max-width: 190px;
    .Input {
      font-family: 'Courier New', monospace;
    }
  }

  &__form-source-value-scan-confirms-type {
    width: 100%;
    max-width: 190px;

    .MuiToggleButtonGroup-root {
      background: #272727;
    }
  }

  &__form-address,
  &__bot-group,
  &__kit-selector,
  &__form-bot {
    display: flex;
    flex-direction: column;
    min-width: 360px;
  }

  &__form-bot {
    width: 100%;
  }

  &__filter-form {
    margin-top: 20px;
  }

  &__bot-group-title {
    margin-bottom: 10px;
  }

  &__bot-list {
    background-color: $darkgray;
    padding: 10px;
    font-size: 0.8em;

    a:hover {
      color: $blue;
    }
  }

  &__header {
    h2 {
      font-size: 1.4em;
    }
  }

  &__information {
    color: $lightgray-2;
    font-weight: 500;
    font-size: 1.125em;
    margin-bottom: 24px;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.6);
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__submit-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      color: white;
      background-color: transparent;
      padding: 10px 50px;
      outline: none;
      font-size: 25px;
      border-radius: 15px;
      border: solid 3px transparent;
      background-image: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(101deg, #3dbbd8 0%, #30fb8a 76.56%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      box-shadow: 2px 1000px 1px #0f0f0f inset;

      &:hover {
        color: white;
      }
    }
  }

  &__fill-wallet-btn {
    display: none;
    position: absolute;
    right: 0;
    color: $gray;
    transition: color 0.2s ease-out;
    font-size: 0.9em;

    &:hover {
      color: $blue;
    }
  }

  &__label-with-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .InfoPopover {
      margin-left: 10px;
    }
  }

  &__endpoint-info {
    position: absolute;
    font-size: 0.8em;
    color: $gray-2;
    right: 0;
  }

  &--delete {
    .NotificationModal__submit-button {
      justify-content: flex-start;
    }
  }

  &__subtype-item {
    p {
      font-size: 0.8em;
    }
  }

  &__dropdown-descriptions {
    .Dropdown__value-container {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    max-width: 400px;
    min-width: 370px;
  }
}

@media screen and (max-width: 520px) {
  .NotificationModal {
    padding-right: 20px;
  }

  .NotificationModal__form-field {
    flex-wrap: wrap;
  }

  .NotificationModal__form-field-label,
  .NotificationModal__form-field-value {
    width: 100%;
  }
}

@keyframes overlay-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
