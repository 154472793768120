@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.GlobalNodePoolsPage {
  font-size: 16px;

  &__pool-id-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-bottom: 40px;
  }

  &__search-input {
    flex: 1;
    width: 100%;
    max-width: 660px;
  }

  &__chain-select {
    width: 100%;
    min-width: 240px;
    max-width: 300px;
  }

  @media #{$breakpoint-md-down} {
    &__search-form {
      flex-direction: column;
      margin-top: 30px;

      > * {
        flex: 1;
        max-width: 100%;
      }
    }
  }
}
