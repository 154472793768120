@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';
@import 'src/common/styles/mixins/transitions';

.AlertsPage {
  position: relative;
  align-self: center;
  text-align: left;

  &__docs-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 10px;

    @media #{$breakpoint-md-down} {
      position: static;
      height: 50px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__title {
    @include h1-responsive();

    text-align: left;
    margin-top: 90px;
    margin-bottom: 38px;
  }

  .SearchBar {
    display: flex;
    margin-top: 32px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 694px;

    &__input {
      flex: 1;

      input {
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__submit {
      border-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  @media #{$breakpoint-lg-down} {
    &__title {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  @media #{$breakpoint-md-down} {
    &__title {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__search-bar {
      text-align: right;
      width: 100%;
      max-width: 100%;
    }
  }
}

.FilterPanel {
  $module: &;
  text-align: left;

  &__networks {
    display: flex;
    gap: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;

    @media #{$breakpoint-sm-down} {
      flex-direction: column-reverse;
    }
  }

  &__network-select {
    display: none;
    width: 100%;
    max-width: 300px;

    @media #{$breakpoint-md-down} {
      display: flex;
    }

    @media #{$breakpoint-sm-down} {
      max-width: 100%;
    }
  }

  &__network-group {
    @media #{$breakpoint-md-down} {
      display: none !important;
    }
  }

  &__main-controls {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  &__filter-button {
    &--active {
      background-color: $neutral-30 !important;
    }
  }

  &__filter-counter{
    display: inline-flex;
    padding: 4px 8px;
    min-width: 24px;
    margin-left: 8px;
    border-radius: 50%;
    color: $neutral-70;
    background-color: $red;
  }

  &__collapse {
    @include transition-slow();

    &[aria-hidden="true"] {
      opacity: 0.5;
    }

    &[aria-hidden="false"] {
      opacity: 1;
    }
  }

  &__body {
    padding-bottom: 20px;
  }
}
