@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/transitions';

.Tabs {
  font-family: $font-display;
  overflow-x: auto;
  overflow-y: hidden;

  &__container {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
  }

  .Tab {
    @include transition-medium();

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-weight: 500;
    gap: 10px;
    font-size: 18px;
    line-height: 24px;
    color: $neutral-50;
    white-space: nowrap;
    border-radius: 8px;

    // This thing allows you to get rid of tab content shift,
    // when we change the weight of the active tab.
    span:before {
      display: block;
      content: attr(data-label);
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:after {
      @include transition-medium();

      position: absolute;
      left: 0;
      bottom: -12px;
      content: '';
      width: 100%;
      height: 4px;
      background-color: transparent;
    }

    &__badge {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1px 10px;
      background: $neutral-30;
      border-radius: 16px;
      min-width: 32px;
    }

    &--active {
      font-weight: 600;
      color: $neutral-100;

      &:after {
        background-color: $secondary-purple;
      }
    }

    &--disabled {
      cursor: default;
      color: $neutral-30;
      background: transparent;

      .Tab__badge {
        color: $neutral-40;
        background-color: $neutral-20;
      }
    }

    &:not(&--disabled) {
      &:hover {
        background-color: $neutral-15;
      }

      &:active {
        background-color: lighten($neutral-15, 4%);
      }
    }
  }

  &__line {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
  }
}
