@import 'src/common/styles/colors';

.PlanLabel {
  &--general {
    padding: 5px 15px;
    gap: 10px;
    height: 27px;
    border-radius: 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid $secondary-purple;
    white-space: nowrap;
  }

  &--scam {
    padding: 5px 15px;
    gap: 10px;
    height: 27px;
    border-radius: 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid $accent-purple;
    white-space: nowrap;
  }

  &--spam {
    padding: 5px 15px;
    gap: 10px;
    height: 27px;
    border-radius: 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid $accent-green-40;
    white-space: nowrap;
  }

  &--rugpull {
    padding: 5px 15px;
    gap: 10px;
    height: 27px;
    border-radius: 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid $accent-pink-10;
    white-space: nowrap;
  }

  &--attack {
    padding: 5px 15px;
    gap: 10px;
    height: 27px;
    border-radius: 40px;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: 2px solid $accent-blue-40;
    white-space: nowrap;
  }
}
