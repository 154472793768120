@import 'src/common/styles/colors';

.ProgressBar {
  background-color: $lightgray-3;
  border-radius: 4px;
  height: 0.2rem;
  width: 100%;

  &__bar {
    background-color: $blue;
    border-radius: 4px;
    height: 100%;
    transition: width 0.3s ease;
  }

  &--secondary {
    .ProgressBar__bar {
      background-color: $yellow;
    }
  }
}
